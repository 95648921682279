import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvents} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import { useQuery, gql } from '@apollo/client'
import MarkerClusterGroup from 'react-leaflet-cluster'
import SearchBar from './SearchBar'
import AppDrawer from './AppDrawer'
import { Drawer, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import RecentApplications from './RecentApplications';

const GET_APPLICATION = gql`
  {
    applications(where: { lookup_authorities_description: "Mid and East Antrim Borough Council" }) {
      application_number
      application_description_1
      location
      proposal
      gridref_east
      gridref_north
      received_date
      received_complete_date,
      application_id,
      lat,
      lon,
      added_date,
      lookup_auto_status_codes_description,
      lookup_authorities_description
    }
  }
`;

const SEARCH_APPLICATION = gql`
  query SearchApplications($sQuery: String!) {
    applications(where: { proposal: $sQuery }) {
      application_number
      application_description_1
      location
      proposal
      gridref_east
      gridref_north
      received_date
      received_complete_date
      application_id
      lat
      lon,
      added_date,
      lookup_auto_status_codes_description,
      lookup_authorities_description
    }
  }
`;

function FitBoundsComp({ markers }) {
  const map = useMap();
  useEffect(() => {
    if (markers.length > 0) {
      const bounds = markers.map(({ lat, lon }) => [lat, lon]);
      map.fitBounds(bounds);
    }
  }, [map, markers]);
  return null;
}

function GetBoundsComp({ setMapBounds }) {
  const map = useMap();
  useEffect(() => {
    const updateBounds = () => {
      const bounds = map.getBounds();
      setMapBounds({
        northEast: bounds.getNorthEast(),
        southWest: bounds.getSouthWest()
      });
    };
    map.on('moveend', updateBounds);
    updateBounds();
    return () => {
      map.off('moveend', updateBounds);
    };
  }, [map, setMapBounds]);
  return null;
}

function MyComponent({ onZoomChange, onCenterChange }) {
  useMapEvents({
    zoomend: (event) => {
      const newZoom = event.target.getZoom();
      onZoomChange(newZoom);
    },
    moveend: (event) => {
      const newCenter = event.target.getCenter();
      onCenterChange(newCenter);
    },
  });
  return null;
}

const Map = () => {
  const defaultMapOptions = {
    position: [54.665970, -6.521124],
    zoom: 9,
  };
  const [mapOptions, setMapOptions] = useState(defaultMapOptions);
  const [searchQuery, setSearchQuery] = useState('');
  const [useFirstLayer, setUseFirstLayer] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [recentApplications, setRecentApplications] = useState(false);
  const [mapBounds, setMapBounds] = useState(null);

  const isDesktop = useMediaQuery('(min-width:600px)');
  const { loading, error, data, refetch } = useQuery(searchQuery ? SEARCH_APPLICATION : GET_APPLICATION, {
    variables: { sQuery: searchQuery },
  });

  useEffect(() => {
    if (error) {
      console.error('Error fetching data: ', error);
    }
  }, [error]);

  useEffect(() => {
    if (data && data.applications.length === 1) {
      setSelectedApplication(data.applications[0]);
      setDrawerOpen(true);
      setPageTitle(data.applications[0])
    }
  }, [data]);

  const setPageTitle = (app) => {
    document.title = `${app.application_number} - PlanningNI.info - Find planning applications in Northern Ireland`; 

    const metaDescription = document.querySelector('meta[name="description"]');
    var desc = app.proposal
    if(desc.length < 150){
      desc += ' - ' + app.location
    }
    desc = desc.substring(0, 150)
    
    if (metaDescription) {
      metaDescription.setAttribute('content', desc);
    } else {
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = desc;
      document.head.appendChild(meta);
    }
  }

  const handleSearch = (query) => {
    setSearchQuery(query);
    refetch({ sQuery: query });
  };

  const handleSearchArea = () => {
    console.log('mapBounds', mapBounds)
    var mBounds = JSON.stringify(mapBounds)
    setSearchQuery(mBounds);
    refetch({ sQuery: mBounds });
  };

  const viewRecentApplications = () => {
    console.log('viewRecentApplications')
    setRecentApplications(true);
    setDrawerOpen(true);
  };

  const toggleLayer = () => {
    setUseFirstLayer((prevState) => !prevState);
  };

  const handleMarkerClick = (application) => {
    setSelectedApplication(application);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedApplication(null);
    setRecentApplications(false);
  };

  const handleMapZoomChange = (newZoom) => {
    setMapOptions((prevOptions) => ({ ...prevOptions, zoom: newZoom }));
  };

  const handleMapCenterChange = (newCenter) => {
    setMapOptions((prevOptions) => ({ ...prevOptions, position: [newCenter.lat, newCenter.lng] }));
  };

  const q = new URLSearchParams(useLocation().search).get('q');

  return (
    <div>
      <SearchBar onSearch={handleSearch} onToggleLayer={toggleLayer} urlQuery={q} />

      <Button variant="contained" color="primary" onClick={handleSearchArea} style={{ position: 'absolute', zIndex: 1000, bottom: 10, width:'55%', marginLeft:'20%' }}>Search This Area</Button>
      <Button variant="contained" color="primary" onClick={viewRecentApplications} style={{ position: 'absolute', zIndex: 1000, bottom: 10, width:'4%', marginLeft:'76%' }}>☰</Button>

      <MapContainer center={mapOptions.position} zoom={mapOptions.zoom} minZoom={8} style={{ width: '100%', height: '100vh' }} zoomControl={false} attributionControl={false}>
        
        <MyComponent onZoomChange={handleMapZoomChange} onCenterChange={handleMapCenterChange} />
        {data && data.applications && <FitBoundsComp markers={data.applications} />}
        <GetBoundsComp setMapBounds={setMapBounds} />
        
        <TileLayer url={useFirstLayer ? 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png' : 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'} />
        
        <MarkerClusterGroup chunkedLoading>
          {data && data.applications.map((a) => (
            <Marker
              position={[a.lat, a.lon]}
              icon={L.icon({iconRetinaUrl: iconRetina, iconUrl: iconRetina, iconSize: [31, 46], iconAnchor: [15.5, 42]})}
              key={a.application_number}
              eventHandlers={{ click: () => handleMarkerClick(a) }}
            />
          ))}
        </MarkerClusterGroup>

        <Drawer anchor={isDesktop ? 'right' : 'bottom'} open={drawerOpen} onClose={handleCloseDrawer} PaperProps={{ sx: { maxHeight: isDesktop ? undefined : '50vh'} }}>
          {selectedApplication && <AppDrawer isDesktop={isDesktop} selectedApplication={selectedApplication}  />}
          {recentApplications && <RecentApplications isDesktop={isDesktop} applications={data.applications}  />}
        </Drawer>

      </MapContainer>
    </div>
  );
};

export default Map;