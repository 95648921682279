import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const RecentApplications = ({isDesktop, applications}) => {

  const columns = [
    { 
      field: 'application_number', 
      headerName: '#', 
      width: 170, 
      hideable: false,
      renderCell: (params) => (
        <a href={`https://planningregister.planningsystemni.gov.uk/application/${params.row.application_id}`} target="_blank" rel="noopener noreferrer">
          {params.value}
        </a>
      )
    },
    { field: 'proposal', headerName: 'Proposal', hideable: false, flex: 2 },
    { field: 'location', headerName: 'Location', hideable: false, flex: 1  },
    { field: 'lookup_auto_status_codes_description', headerName: 'Status', width:90, hideable: false },
    { field: 'added_date', headerName: 'Added',  width: 110, hideable: false },
  ];

  const appsWithIds = applications.map((row, index) => ({
    ...row,
    id: index, // use application_number if available, otherwise use the index
  }));

  console.log('applications',applications)
  
  return (
    <div style={{ width: isDesktop ? '80vw' : '100vw', padding: '10px', height:'100%', display:'flex', flexDirection:'column' }}>
      <h2>Application Overview</h2>
      <DataGrid
        rows={appsWithIds}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
          sorting: {
            sortModel: [{ field: 'added_date', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[100,500]}
      />
    </div>

  );
}

export default RecentApplications;
