import * as React from 'react';
import BottomNavigation  from '@mui/material/BottomNavigation';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RecentApplications from './RecentApplications'

import Map from './Map';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const isLocal = process.env.NODE_ENV === 'development';

const client = new ApolloClient({
  uri: isLocal ? 'http://localhost:5000/api' : 'https://planningni.info:5000/api',
  cache: new InMemoryCache()
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path="" element={<Map />} />
          <Route path="/RecentApplications" element={<RecentApplications />} />
        </Routes>
        <BottomNavigation showLabels style={{ position: 'absolute', bottom: 0, zIndex: 999, width: '100%', display: 'none' }}>

        </BottomNavigation>
      </Router>
    </ApolloProvider>
  );
}
