import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import LayerIcon from "@mui/icons-material/Layers";
import Grid from "@mui/material/Grid";

const SearchBar = ({ onSearch, onToggleLayer, urlQuery }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (urlQuery) {
      setInputValue(urlQuery);
      console.log('urlQuery', urlQuery)
      onSearch(urlQuery);
    }
  }, [urlQuery]);

  // useEffect(() => {
  //   if (inputValue) {
  //     handleSearch();
  //   }
  // }, [inputValue]);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

  };

  const handleSearch = () => {
    onSearch(inputValue);
    var qValue = inputValue.replaceAll(' ','+')
    window.history.pushState({}, '', `${window.location.pathname}?q=${qValue}`);
  };

  return (
    <div style={{ position: 'absolute', top: '10px', zIndex:999, display:'flex', width:'100%', justifyContent:'center' }}>
      <Grid container spacing={1}>
        <Grid item xs={0} md={4} />
        <Grid item xs={9} md={4}>
          <TextField id="search-bar" fullWidth className="text" style={{backgroundColor:'#FFF'}} label="Search planning applications" variant="outlined" placeholder="Search..." size="small" value={inputValue} onChange={handleSearchInputChange} onKeyDown={e => e.keyCode == 13 && handleSearch()}/> 
        </Grid>
        <Grid item xs={1} md={3}>
          <IconButton type="submit" aria-label="search" style={{backgroundColor:'#FFF'}} onClick={handleSearch}><SearchIcon /></IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton type="submit" aria-label="toggle layers" style={{backgroundColor:'#FFF'}} onClick={onToggleLayer}><LayerIcon /></IconButton>
        </Grid>
      </Grid>
    </div>
  )
};

export default SearchBar;
