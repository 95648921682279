import * as React from 'react';
import { Typography, Divider, Box, IconButton } from '@mui/material';
import PersonPin from "@mui/icons-material/PersonPinSharp";


const AppDrawer = ({ isDesktop, selectedApplication }) => {
  console.log(selectedApplication)

  const handleStreetViewClick = () => {
    const lat = selectedApplication.lat;
    const lon = selectedApplication.lon;
    const streetViewUrl = `https://www.google.com/maps?q=&layer=c&cbll=${lat},${lon}`;
    window.open(streetViewUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div style={{ width: isDesktop ? '25vw' : '100vw', padding: '10px', height:'100%', display:'flex', flexDirection:'column' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h5" component="div">
        <a
          href={`https://planningregister.planningsystemni.gov.uk/application/${selectedApplication.application_id}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: '#535353' }}
        >
          {selectedApplication.application_number}
        </a>
      </Typography>
      <IconButton
        type="submit"
        aria-label="search"
        style={{ backgroundColor: '#757575', color: '#FFF' }}
        onClick={handleStreetViewClick}
      >
        <PersonPin />
      </IconButton>
    </div>
    <Divider style={{margin:'10px 0'}} />
    <Typography variant="subtitle1" gutterBottom>
      <b>Proposal</b><br/>
      {selectedApplication.proposal}
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      <b>Location</b><br/>
      {selectedApplication.location}
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      <b>Status</b><br/>
      {selectedApplication.lookup_auto_status_codes_description}
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      <b>Council</b><br/>
      {selectedApplication.lookup_authorities_description}
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      <b>Date Added</b><br/> 
      {selectedApplication.added_date}
    </Typography>
    <Box sx={{ marginTop: 'auto', textAlign: 'center',  paddingBottom: 2 }}>
      <Typography variant="h5" component="h1">
        PlanningNI.info
      </Typography>
      <Typography variant="subtitle1" component="h2">
        Find planning applications in Northern Ireland
      </Typography>
    </Box>
  </div>
  );
}

export default AppDrawer
